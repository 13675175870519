<template>
  <div class="content asset-storage-location">
    <section style="margin-bottom: 1%">
      <a-button type="primary" @click="createHandle">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('新增存放地点') }}
      </a-button>
    </section>
    <CommonTable
      ref="CommonTableRef"
      :columns="columns"
      :loading="loading"
      :dataSource="tableData"
      :total="total"
      @search="getList"
      :showSetting="false"
    >
      <template v-slot:isEnabled="{ text }">
        <span class="text-success" v-if="!!+text">{{ $t('启用') }}</span>
        <span v-else class="text-danger">{{ $t('禁用') }}</span>
      </template>
    </CommonTable>
    <a-modal
      v-model="visible"
      :title="$t('存放地点')"
      :keyboard="false"
      :maskClosable="false"
      @cancel="visible = false"
      @ok="okHandle"
      :confirmLoading="confirmLoading"
    >
      <div class="form-content">
        <a-form-model>
          <a-form-model-item :label="$t('编号')" required>
            <a-input v-model="modalData.locationCode"></a-input>
          </a-form-model-item>
          <a-form-model-item :label="$t('名称')" required>
            <a-input v-model="modalData.locationName"></a-input>
          </a-form-model-item>
          <a-form-model-item :label="$t('创建组织')">
            <CommonSelect
              :isBu="true"
              :list="$store.state.settlementBuList"
              :placeholder="$t('创建组织')"
              :code.sync="modalData.createBusinessUnitCode"
              :name.sync="modalData.createBusinessUnitName"
            />
          </a-form-model-item>
          <a-form-model-item :label="$t('行政区划')" required>
            <a-cascader
              v-model="addressidList"
              :options="options"
              :load-data="loadData"
              @change="selectAddress"
              :field-names="{
                label: 'display_name',
                value: 'region_id',
                children: 'children',
              }"
              :placeholder="$t(`行政区划`)"
            />
          </a-form-model-item>
          <a-form-model-item :label="$t('详细地址')">
            <a-input v-model="modalData.detailAddress" @blur="getFullAddress"></a-input>
          </a-form-model-item>
          <a-form-model-item :label="$t('地址全称')">
            <a-input v-model="modalData.fullAddress" disabled></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { Cascader } from 'ant-design-vue'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'AssetStorageLocation',
  components: { CommonTable, 'a-cascader': Cascader },
  data() {
    return {
      formData: {},
      tableData: [],
      total: 0,
      loading: false,
      modalData: {
        locationCode: undefined,
        locationName: undefined,
        createBusinessUnitCode: undefined,
        createBusinessUnitName: undefined,
        detailAddress: undefined,
        fullAddress: undefined,
      },
      visible: false,
      confirmLoading: false,
      options: [],
      addressidList: [], //地址
      addressDataList: [],
    }
  },
  activated() {
    this.searchHandle()
  },
  mounted() {
    this.getCountry()
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('编号'),
          dataIndex: 'locationCode',
        },
        {
          title: this.$t('存放地点'),
          dataIndex: 'locationName',
        },
        {
          title: this.$t('地址全称'),
          dataIndex: 'fullAddress',
        },
        // {
        //   title: this.$t('数据状态'),
        //   dataIndex: 'locationStatus',
        // },
        {
          title: this.$t('使用状态'),
          dataIndex: 'isEnabled',
          scopedSlots: { customRender: 'isEnabled' },
        },
        {
          title: this.$t('创建组织'),
          dataIndex: 'createBusinessUnitName',
        },
      ]
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    getCountry() {
      http({
        url: api.getRegionList,
        type: 'post',
        data: {
          regionType: '0',
          parentId: '0',
        },
        success: (res) => {
          let data = res.result
          this.options = data.map((item) => {
            item.isLeaf = false
            return item
          })
        },
      })
    },
    async loadData(selectedOptions) {
      this.type = selectedOptions.length
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      await http({
        url: api.getRegionList,
        type: 'post',
        data: {
          regionType: this.type,
          parentId: targetOption.region_id,
        },
        success: (res) => {
          let data = res.result
          targetOption.loading = false
          targetOption.children = [...data]
          if (this.type <= 3) {
            targetOption.children = [...data].map((item) => {
              item.isLeaf = selectedOptions.length === 3 // deep 为指名数据的层级
              return item
            })
          }
          this.options = [...this.options]
        },
      }) // 接口调用
    },
    selectAddress(ids, names) {
      this.addressidList = [...ids]
      this.addressDataList = [...names]
    },
    searchHandle(pageNo) {
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    createHandle() {
      this.visible = true
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getStoreLocationList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    async okHandle() {
      this.confirmLoading = true
      await http({
        url: api.saveStoreLocation,
        data: {
          storeLocation: {
            ...this.modalData,
            countryId: this.addressidList[0],
            provinceId: this.addressidList[1],
            cityId: this.addressidList[2],
            districtId: this.addressidList[3],
            isEnabled: 1,
            merchantCode: localStorage.getItem('merchantCode'),
          },
        },
        success: (res) => {
          if (res.success) {
            this.$message.success('新增成功')
            this.searchHandle()
            this.visible = false
          }
        },
      })
      this.confirmLoading = false
    },
    getFullAddress() {
      this.modalData.fullAddress =
        this.addressDataList.map((item) => item.display_name).join('') +
        this.modalData.detailAddress
    },
  },
}
</script>
